import { transition, trigger,query,style,animateChild,group, animate } from '@angular/animations';
import { Component} from '@angular/core';
import { RouterOutlet } from '@angular/router';

  
@Component({
    selector: 'app-component',
    template: `
    <div [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
    `,

    animations: [
        trigger('routeAnimations',[
            transition('Page => Landing',[
                group([
                    query(':enter', [
                        style({
                            position: 'absolute',
                            // top: 0,
                            top: "calc((100svh - 100lvh) / 2)",
                            right: 0,
                            width: '100%',
                        }),
                    ]),
                    query(':leave',[
                        style({
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '100%',
                        }),
                    ])
                ]),
                group([
                    query(':leave', [
                        style({
                            opacity: 1
                        }),
                        animate('1s linear', style({opacity: 0 })),
                    ]),
                    query(':enter', [
                        style({ opacity: 0}),
                        animate('1s linear', style({  opacity: 1 })),
                    ])
                ]),
            ]),

            transition('Landing => Page',[
                group([
                    query(':enter', [
                        style({
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '100%'
                        }),
                    ]),
                    query(':leave', [
                        style({
                            position: 'absolute',
                            top: "calc((100svh - 100lvh) / 2)",
                            right: 0,
                            width: '100%'
                        }),
                    ]),
                ]),
                group([
                    query(':leave', [
                        group([
                            query('@header,@howIt,@service,@faqs,@footer,@careers',[
                                animateChild()
                            ],{optional: true}),
                            style({
                                opacity: 1
                            }),
                            animate('1s linear', style({opacity: 0 })),
                        ])
                    ]),
                    query(':enter', [
                        style({ opacity: 0}),
                        animate('1s linear', style({  opacity: 1 })),
                    ])
                ]),
            ])
        ])
    ]
})

export class AppComponent {
    constructor() {}
    previousState;
    prepareRoute(outlet: RouterOutlet) {
        return outlet && 
          outlet.activatedRouteData && 
          outlet.activatedRouteData['animationState'];
    }
}