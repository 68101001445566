<div class="content-wrapper">
    @if (loading) {
        <div [@fadeOutIn] class="loading">
            <img draggable="false" class="logo"  src="../assets/Other/Logo-white.svg"  alt="loading">
            <img draggable="false" loading='lazy' class="circle" src="../assets/Other/Loading-circle.webp"  alt="loading">
        </div>
    }

    <!-- Menu -->
    @if (menuShown) {
        <section [@menu]  class="menu">
            <main class="main">
                <div class="top">
                    <div class="goBack" (click)="menuToggl()">
                        <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="back">
                        <div class="text">Back</div>
                    </div>
                    <span class="top__el">Menu</span>
                </div>
                <div class="list">
                    <div class="list__el">
                        <span (click)="goToQuote()"  id="getQuote" class="text">Get A Quote</span>
                    </div>
                    <p  target="blank" class="list__el">
                        <a tabindex="-1" href="https://app.haulex.com/#/login" title="HaulEx site login" class="text">Login</a>
                    </p>
                </div>
                <ul  class="sub-list" (click)="goToSection($event)">
                    <li class="sub-list__el" id="stats">Our Focus</li>
                    <li class="sub-list__el" id="service">Service Options</li>
                    <li class="sub-list__el" id="map">Map</li>
                    <li class="sub-list__el" id="howIt">How Quote Works</li>
                    <li class="sub-list__el" id="careers">Careers</li>
                    <li class="sub-list__el" id="faqs">FAQ`S</li>
                    <li class="sub-list__el" id="reviews">Reviews</li>
                </ul>
            </main>
            <a tabindex="-1" href="tel:480-360-1265" title="HaulEx app login" class="number">(480) 360-1265</a>
    
        </section>
    }
    
    <!-- Header -->
    <div class="section-wrapper" [@header]="selectedSection" >
        <header  [@headerElements]="selectedSection" (@headerElements.done)="animationFinished($event)" id="header" class="header">
            
            <main class="main">
                <div class="content-wrapperr">
                    <nav  class="nav">
                        <img draggable="false" loading="lazy" class="nav__logo" src="../assets/Other/vsg-white.svg" alt="Diesel Dispatch">
                        <div class="nav__sidebar">
                            @if (['desktop','macxl','mac','ipadxl','tabletxl'].includes(type)) {
                                <a tabindex="-1" class="number" title="help phone number" href="tel:480-360-1265">(480) 360-1265</a>
                            }
                            @if (type !== 'mobile') {
                                <img draggable="false" class="img" src="../assets/Other/Logo-white.svg" alt="HaulEx logo">
                                <a tabindex="-1" class="text" href="https://app.haulex.com/#/login" title="HaulEx app login">Login</a>
                                <div class="white-line"></div>
                            }
                            <img draggable="false" [ngStyle]="{ marginRight: type !== 'mobile' ? '0px' : '10px' }" (click)="menuToggl()" class="burger" src="../assets/Other/burger-white.svg" alt="Menu">
                        </div>
                    </nav>
                    <div class="main-text">
                        <div>
                            <div class="title">
                                <!-- Each word divided to separated p to provide step animation to each word with different interval appearing -->
                                 <div class="cont">
                                     <p>N</p><p>a</p><p>t</p><p>i</p><p>o</p><p>n</p><p>w</p><p>i</p><p>d</p><p>e</p>
                                 </div>
                                 @if(type === 'ipadxl'){
                                    <div class="cont">
                                        <p>A</p><p>u</p><p>t</p><p>o</p><p>&nbsp;</p><p>T</p><p>r</p><p>a</p><p>n</p><p>s</p><p>p</p><p>o</p><p>r</p><p>t</p>
                                        <p>S</p><p>e</p><p>r</p><p>v</p><p>i</p><p>c</p><p>e!</p>
                                    </div>
                                }@else{
                                    <div class="cont">
                                        <p>A</p><p>u</p><p>t</p><p>o</p><p>&nbsp;</p><p>T</p><p>r</p><p>a</p><p>n</p><p>s</p><p>p</p><p>o</p><p>r</p><p>t</p>
                                    </div>
                                    <div class="cont">
                                        <p>S</p><p>e</p><p>r</p><p>v</p><p>i</p><p>c</p><p>e!</p>
                                    </div>
                                }

                             </div>
                             <div class="sub-text">
                                Fast & Reliable Service In All 50 States!
                             </div>
                        </div>

                        
                        <!-- @if (['desktop','macxl','mac'].includes(type) && !linkLoaded) {
                            <img draggable="false" #img src="../assets/Other/quote-form-thumbnail.webp" class="getAQuote thumbnail absolute right-0">
                        } -->
                        @if (['desktop','macxl','mac'].includes(type) && linkLoaded) {
                            <iframe class="getAQuote" 
                            title="Get A Quote"
                            #iframeForm
                            style="border-width: 0;" 
                            data-referenceid="nQ04AQCwUi" 
                            data-source="VehicleShippingGroup" 
                            data-onsuccess="thankyou" 
                            [src]="link"
                            [attr.data-url]="dataUrl"
                            data-gtm-yt-inspected-7="true">
                            </iframe>
                        }
                        
                    </div>
                    <div class="bottom">
                        <button tabindex="-1" (click)="goToQuote()" class="bottom__button quote">Get a quote</button>
                        <button tabindex="-1" class="bottom__button track">Track my vehicle</button>
                    </div>
                </div>
            </main>
    
            <div class="gradient"></div> 
    
        </header>
    </div>

    <!-- Stats -->
    <div class="section-wrapper" [@stats]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('stats') ? true : false" [@statsElements]="{value: selectedSection, params: {
            rotation: arrowRotation === 'horizontal' ? 'translateX(-30%)' : 'translateY(-30%)'
        }}" (@statsElements.done)="animationFinished($event)" id="stats" class="stats">
            <main class="main">
                @if (['mobile','tablet','ipad'].includes(type)) {
                    <div (click)="goToQuote()"  id="getQuote"  class="getAQuote-wrapper getA">
                        <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                        <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                    </div>
                }
                
                <div class="title-wrapper">
                    <h2 class="text">
                        <p>Since 2017,</p>
                        <p>our Focus</p> 
                        <p>is still the same.</p>  
                    </h2>
                    <h2 class="text">
                        <p>Simply provide the</p>
                        <p>best auto transport</p>
                        <p>experience to our</p>
                        <p>customer!</p>
                    </h2>
                </div>
                <div class="image-down">
                    <!-- <div class="arrow-conteiner"> -->
                        <picture class="img">
                            <source srcset="
                            ../assets/Other/slide-down2.svg,
                            " media="(min-width: 1280px)" />
                            <source srcset="
                            ../assets/Other/slide-down3.svg,
                            " media="(min-width: 1920px)" 
                            />
                            <img draggable="false" class="img_" src="../assets/Other/slide-down.svg" alt="">
                        </picture>
                    <!-- </div> -->
                </div>
                @if (type === 'mobile' || type === '') {
                    <div class="swiper-stats swiper">
                        <div class="stats-tape swiper-wrapper">
                            <div class="stat swiper-slide">
                                <h3 class="title">2475+</h3>
                                <p class="sub-text">Cars Moved <br> Each Month</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">150+</h3>
                                <p class="sub-text">Hot Routes <br> Serviced Weekly</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">55+</h3>
                                <p class="sub-text">Trucks Under <br> Our Family</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">1269+</h3>
                                <p class="sub-text">Carriers In <br> Our Network</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">100%</h3>
                                <p class="sub-text">Compliant <br> &  Insured</p>
                            </div>
                        </div>
                    <div class="arrow-toggls">
                        <img draggable="false" class="img swiper-button-prev" src="../assets/Other/chevron-white.svg" alt="left">
                        <img draggable="false" class="img swiper-button-next" src="../assets/Other/chevron-white.svg" alt="right">
                    </div>
                </div>
                }
                @if (type !== 'mobile' && type !== '') {
                    <div class="stats">
                        @if (!['mobile','tablet','ipad'].includes(type)) {
                            <div (click)="goToQuote()"  id="getQuote" class="getAQuote-wrapper getA">
                                <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                                <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                            </div>
                        }
                        
                        <div class="stat first">
                            <h3 class="title">2475+</h3>
                            <p class="sub-text">Cars Moved Each Month</p>
                        </div>
                        <div class="stat second">
                            <h3 class="title">150+</h3>
                            <p class="sub-text">Hot Routes Serviced Weekly</p>
                        </div>
                        <div class="stat third">
                            <h3 class="title">55+</h3>
                            <p class="sub-text">Trucks Under Our Family</p>
                        </div>
                        <div class="stat fourth">
                            <h3 class="title">1269+</h3>
                            <p class="sub-text">Carriers In Our Network</p>
                        </div>
                        <div class="stat fifth">
                            <h3 class="title">100%</h3>
                            <p class="sub-text">Compliant & Insured</p>
                        </div>
                    </div>
                }
                
            </main>
        </section>
    </div>
    
    <!-- Service -->
    <div class="section-wrapper" [@service]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('service') ? true : false" [@serviceElements]='selectedSection' (@serviceElements.done)="animationFinished($event)" id="service" class="service">
            <main class="main">
                <div class="main-text-container">
                    <h2 class="title">Services</h2>
                    <span class="text">Customized Solutions: We understand that every business is unique. Our team works closely with you to develop custom logistics solutions that align with your specific requirements and objectives.</span>
                </div>

                <div class="tape-conteiner" id="tape-conteiner">
                        <div class="title-conteiner">
                        @if(type === 'mobile'){
                            @if(
                                [3,4,5,0].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Service Options</h3>
                            }
                            @if(
                                [1,2,6].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Transport Types</h3>
                            }
                        }
                        @if(['tablet','ipad'].includes(type)){
                            @if(
                                [2,3,4,5,9].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Service Options</h3>
                            }
                            @if(
                                [0,1].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Transport Types</h3>
                            }
                        }

                        </div>
                        @if(type ==='mobile'){
                            <div [@mobileServiceToggl]="{
                                value:serviceIndex
                            }" (@mobileServiceToggl.start)="serviceAnimStart($event)" (@mobileServiceToggl.done)="serviceAnimFinish($event)" class="types-wrapper">
                                <div id="types-conteiner" class="types-conteiner">
                                    <!-- <div class="card-tape-wrapper"> -->
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Flexible prime<br> Expedited</h4>
                                                            <p class="text">
                                                                Choosing Flex is a standby service just like on the airlines, only available with another shipments cancelation or delay. Most affordable, but you don't have the luxury of having your shipment scheduled ahead of time, more last minute notice & not always available.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/flexible-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Transport Types</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <p class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <!-- <h3 class="title">Transport Types</h3> -->
                                            <div class="card">
                                                <main class="card-body">
                                                    <div class="body-wrapper">
                                                        <h4 class="heading">Enclosed Auto<br> Transport</h4>
                                                        <p class="text">
                                                            Our enclosed auto transport fleet consists of meticulously maintained, climate-controlled trailers designed to shield your vehicle from the elements and road debris. This level of protection ensures that your prized possession arrives at its destination in the same pristine condition it left.
                                                        </p>
                                                        <img draggable="false" class="img" src="../assets/service/enclosed-service.svg" alt="Enclosed">
                                                    </div>
                                                </main>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Expidited</h4>
                                                            <p class="text">
                                                                You can choose expedited service option when you have urgent, time sensitive schedules. Your shipment is prioritized to the front of the line with team drivers upon availability. This allows your vehicle to be delivered faster then industry standard without jeopardizing the safety of your vehicle or drivers.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/expidited-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Prime</h4>
                                                            <!-- <img draggable="false" class="popular-img" src="../assets/faqs/popular.webp" alt="popular"> -->
                                                            <p class="text">
                                                                Prime service option is our featured and most popular transport service. Prime service is the  perfect blend of affordability, reliability and efficiency of time ensuring your vehicle arrives safely & on time to its destination.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/prime-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Flexible prime<br> Expedited</h4>
                                                            <p class="text">
                                                                Choosing Flex is a standby service just like on the airlines, only available with another shipments cancelation or delay. Most affordable, but you don't have the luxury of having your shipment scheduled ahead of time, more last minute notice & not always available.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/flexible-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Transport Types</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <p class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                    <!-- </div> -->
                                </div>
                                
                            </div>
                        }
                        
                        @if (type !== 'mobile') {
                            <div class="types-wrapper" [@desktopServiceToggl]="{
                                value:serviceIndex,
                                params: {
                                    first: serviceOptions.positions.first,
                                    second: serviceOptions.positions.second,
                                    third: serviceOptions.positions.third,
                                    last: serviceOptions.positions.last,
                                    firstShake: serviceOptions.positions.firstShake,
                                    lastShake: serviceOptions.positions.lastShake,
                                    lastPlace: serviceOptions.lastServiceSection
                                },
                            }" #serviceSection (@desktopServiceToggl.start)="serviceAnimStart($event)" (@desktopServiceToggl.done)="serviceAnimFinish($event)">
                                @if(['tabletxl','ipadxl','mac','macxl','desktop'].includes(type)){
                                    <div class="title-conteiner">
                                        <h3 class="title">Transport Types</h3>
                                        <h3 class="title">Service Options</h3>
                                    </div>
                                }
                                <div id="types-conteiner" class="types-conteiner">
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Transport Types</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <p id="open" class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card">
                                                <main class="card-body">
                                                    <div class="body-wrapper">
                                                        <h4 class="heading">Enclosed Auto<br> Transport</h4>
                                                        <p id="enclosed" class="text">
                                                            Our enclosed auto transport fleet consists of meticulously maintained, climate-controlled trailers designed to shield your vehicle from the elements and road debris. This level of protection ensures that your prized possession arrives at its destination in the same pristine condition it left.
                                                        </p>
                                                        <img draggable="false" class="img" src="../assets/service/enclosed-service.svg" alt="Enclosed">
                                                    </div>
                                                </main>
                                            </div>
                                        </div>
                                        <div class="arrow-wrapper">
                                            <div class="arrow-conteiner">
                                                <img draggable="false" class="arrow" src="../assets/service/shake-arrow.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Expidited</h4>
                                                            <p id="expedited" class="text">
                                                                You can choose expedited service option when you have urgent, time sensitive schedules. Your shipment is prioritized to the front of the line with team drivers upon availability. This allows your vehicle to be delivered faster then industry standard without jeopardizing the safety of your vehicle or drivers.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/expidited-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Prime</h4>
                                                            <!-- <img draggable="false" class="popular-img" src="../assets/faqs/popular.webp" alt="popular"> -->
                                                            <p id="prime" class="text">
                                                                Prime service option is our featured and most popular transport service. Prime service is the  perfect blend of affordability, reliability and efficiency of time ensuring your vehicle arrives safely & on time to its destination.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/prime-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <h4 class="heading">Flexible</h4>
                                                            <p id="flexable" class="text">
                                                                Choosing Flex is a standby service just like on the airlines, only available with another shipments cancelation or delay. Most affordable, but you don't have the luxury of having your shipment scheduled ahead of time, more last minute notice & not always available.
                                                            </p>
                                                            <img draggable="false" class="img" src="../assets/service/flexible-service.svg" alt="Open">
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        }
                </div>

                <div class="toggl-arrows">
                    <div class="arrow-wrapper">
                        <button tabindex="-1" class="arrow" (click)="serviceTogglFunc(-1)" >
                            <img draggable="false" class="img" src="../assets/Other/long-arrow.svg" alt="left">
                        </button>

                        <button tabindex="-1" class="arrow right" (click)="serviceTogglFunc(1)">
                            <img draggable="false" class="img" src="../assets/Other/long-arrow.svg" alt="right">
                        </button>
                    </div>
                    <div class="progress-bar">
                        <div class="progress-bar__line"></div>
                        {{serviceIndex}}
                            <div [ngStyle]="{
                                'translate': this.serviceOptions.progress[serviceIndex]
                            }" class="progress-bar__active"></div>
                    </div>
                </div>

            
            </main>
        </section>
    </div>
    
    <!-- Map -->
    <div class="section-wrapper" [@map]="selectedSection">
    <section [attr.data-disabled]="selectedSections.includes('map') ? true : false" [@mapElements]="selectedSection" (@mapElements.done)="animationFinished($event)" id="map" class="map">
        <main class="main">
            <h2 class="title">Real Time Pickups & Deliveries {{year}}</h2>
            <div class="map-view-conteiner">
                <div class="gradient-overlay1"></div>
                
                <div id="map-wrapper" class="map-wrapper" [ngStyle]="{ overflowX: videoLoading ? 'hidden' : 'auto' }">
                    
                    <div #videoConteiner id="video-conteiner" class="video-conteiner">
                        <div class="gradient-overlay3"></div>
                        @if (!supportsVideo) {
                        <img draggable="false" #map id="video" src="../assets/Map/Map-black.mp4" class="map-video"  alt="">
                        }

                        <!--  -->
                        @if (supportsVideo) {
                            <video id="video" (waiting)="videoWaiting()" (playing)="videoPlaying()" poster="../assets/Map/Map-thumbnail.webp" class="map-video" preload="yes" [controls]="!touchedOnce" #map [defaultMuted]="true" muted playsinline [playsInline]="true">
                                <source class="video_" src="../assets/Map/Map.mov" type="video/mp4">
                                <source class="video_" src="../assets/Map/Map.mp4" type="video/mp4">
                                <source class="video_" src="../assets/Map/Map.webm" type="video/webm">
                                <source class="video_" src="../assets/Map/Map.mov" type="video/mov">
                                <p class="text-[30px] text-red font-poppins z-[50] absolute top-[50%] left-[50%]">Your browser doesn`t support video tag</p>
                            </video>
                        }
                        @if (videoLoading) {
                            <div [@fadeOutIn] class="loading video">
                                <img draggable="false" class="logo"  src="../assets/Other/Logo-white.svg"  alt="loading">
                                <img draggable="false" loading='lazy' class="circle" src="../assets/Other/Loading-circle.webp"  alt="loading">
                            </div>
                        }
                        
                        <div class="gradient-overlay4"></div>
                    </div>
                </div>
                <div class="gradient-overlay2"></div>
                
            </div>
            @if ((!(['desktop'].includes(type))) && showArrow) {
                <div [@mapArrow] (click)="showNextSection()" class="arrow-down">
                    <img draggable="false" src="../assets/Map/arrow-down.svg" alt="NEXT">
                </div>
            }
        </main>
    </section>
    </div>

    <!-- HowIt -->
    <div class="section-wrapper" [@howIt]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('howIt') ? true : false" [@howItElements]="selectedSection" (@howItElements.done)="animationFinished($event)" id="howIt" class="howIt">
            <main class="main">
                @if (['desktop','macxl'].includes(type)) {
                    <h2 class="howIt-title">How It Works</h2>
                }
                <div class="content-wrapperr">
                    <div class="logo-wrapper">
                        <div>
                            <img class="haulex" draggable="false" src="../assets/Other/haulex-white.svg" alt="">
                            <img [@howItNoiseFlyTop]="howItIndex" class="white-noise" draggable="false" src="../assets/Other/haulex-white-noise-top.svg" alt="">
                            <img [@howItNoiseFlyRight]="howItIndex" class="white-noise" draggable="false" src="../assets/Other/haulex-white-noise-right.svg" alt="">
                            <img [@howItNoiseFlyBottom]="howItIndex" class="white-noise" draggable="false" src="../assets/Other/haulex-white-noise-bottom.svg" alt="">
                            <!-- <img [@howItNoiseFlyLeft]="{value: howItIndex, params: {
                                
                            }}" class="white-noise" draggable="false" src="../assets/Other/haulex-white-noise-left.svg" alt=""> -->
                        </div>
                    </div>
                    <div class="text-wrapper">
                        <div class="text-container">
                            <div class="heading__wrapper" [ngClass]="{
                                'big': howItIndex === 1
                            }">
                                
                                @if(howItIndex === 1){
                                    <h3 [@fadeOutIn] class="heading">Get Quote & Reserve Spot On Our Truck!</h3>
                                }
                                @if(howItIndex === 2){
                                    <h3 [@fadeOutIn] class="heading">Pickup Your Vehicle</h3>
                                }
                                @if(howItIndex === 3){
                                    <h3 [@fadeOutIn] class="heading">Deliver Your Vehicle</h3>
                                }
                                
                            </div>
                            <div class="text__wrapper">
                                @if(howItIndex === 1){
                                    <p [@fadeOutIn] class="text">
                                        Experience the ultimate convenience with our hassle-free service. Get your instant quote for transportation and secure your spot on our truck effortlessly. Our streamlined process ensures that you receive a quick and accurate cost estimate, allowing you to plan your logistics with ease
                                        <br><br>
                                        Once you've obtained your quote, take the next step by reserving your space on our reliable truck. We prioritize efficiency and transparency, making the entire process seamless and stress-free. Trust us to handle your transportation needs promptly and professionally.
                                    </p>
                                }
                                @if(howItIndex === 2){
                                    <p [@fadeOutIn] class="text">
                                        Whether it's a compact hatchback or a standard family car, our professional team guarantees a streamlined and efficient pickup process. Opt for a service that understands the unique requirements of ordinary cars, ensuring a personalized and stress-free experience for all your vehicle pickup needs.
                                    </p>
                                }
                                @if(howItIndex === 3){
                                    <p [@fadeOutIn] class="text">
                                        From the moment you entrust us with your car to its timely arrival at the destination, our dedicated team ensures a seamless and stress-free delivery experience. Choose us for a service that understands and prioritizes the unique requirements of ordinary cars.
                                    </p>
                                }
                                
                            </div>
                        </div>
                        <div class="button-container">
                            <button tabindex="-1" class="button" routerLink="/quote-form" type="button">Get A Quote</button>
                            @if(['mobile','tablet','ipad'].includes(type)){
                                <button tabindex="-1" (click)="showNextSection()" class="chevron-button">
                                    <img src="../../assets/Other/chevron-white.svg" alt="">
                                    <span>scroll down</span>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </section>
    </div>

    <!-- Careers -->
    <div class="section-wrapper" [@careers]="selectedSection">
    <section [attr.data-disabled]="selectedSections.includes('careers') ? true : false" [@careersElements]="selectedSection" (@careersElements.done)="animationFinished($event)" id="careers" class="careers">
        <div class="gradient gradient1"></div>
        <main class="main">
            <div class="content">
                <h2 class="title">Careers in auto @if(type ==='ipad'){<br>}transport</h2>
                <span class="sub-text">Join The Best Team In The Industry</span>
                <button tabindex="-1" class="button"
                routerLink="/cand-form"
                >Im interested</button>
            </div>
        </main>
        <div class="gradient gradient2"></div>
    </section>
    </div>

    <!-- Faqs -->
    <div class="section-wrapper" [@faqs]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('faqs') ? true : false" [@faqsElements]='selectedSection' (@faqsElements.done)="animationFinished($event)" id="faqs" class="faqs">
            <main class="main">
                <div class="top">
                    <div class="text-wrapper">
                        <h2 class="title">faq`s</h2>
                    </div>
                    <div (click)="goToQuote()" id="getQuote" class="getAQuote-wrapper getA">
                        <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                        <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                    </div>
                </div>
                <ul class="faqs-list">
                    <!-- This version of faq`s is showing only on mobile -->
                    @if(['mobile'].includes(type)){
                        <div class="list-conteiner">
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                    <!-- Tablet, Ipad -->
                    @if(['tablet','ipad'].includes(type)){
                        <div class="list-conteiner">
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                           
                            
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                           
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                    <!-- Tabletxl,Ipadxl,mac,macxl,desktop -->
                    @if(!(['tablet','ipad','mobile']).includes(type)){
                        <div class="list-conteiner">
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                </ul>
                <div class="bottom">
                    @if(faqSectionIndex !== maxFaqSecInd){
                        <button tabindex="-1" (click)="morelessFaqs(1)" class="more button">
                            More
                            <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="">
                        </button>
                    }
                    @if(faqSectionIndex !== 1){
                        <button tabindex="-1" (click)="morelessFaqs(-1)" class="less button" [ngClass]="{
                            'less-margin': maxFaqSecInd !== faqSectionIndex
                        }">
                            <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="">
                        </button>
                    }
                </div>
            </main>
        </section>
    </div>

    <!-- Reviews -->
    <div class="section-wrapper" [@reviews]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('reviews') ? true : false" [@reviewsElements]="selectedSection" (@reviewsElements.done)="animationFinished($event)" id="reviews" class="reviews">
            <main class="main">
                <h2 class="title">
                    Customer Who`ve @if (['mobile','tablet','ipad'].includes(type)) {<br>} shared their
                    <p>experience with us.</p>
                </h2>
                <div class="tape_wrapper">
                    <div class="tape-conteiner">
                        <div data-place="first" class="tape" (click)="togglReviewsAnimation($event,'first')" >
                            
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading="lazy" class="logo" src="../assets/reviews/review1(Justin).webp" alt="Justin B">
        
                                    <span class="text">
                                        Great experience working with Danielle moving our car from Oregon to Texas. The price was great and most..
                                        <a tabindex="-1" class="gray" title='Google review' title="Google review" target="_blank" href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSUNxa191OWJ3EAE!2m1!1s0x0:0x9bf3f822de592a5" class="gray">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Justin B
                                </p>
        
                                
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review2(Joyce).webp" alt="Joyce Castillo">
                                    <span class="text">
                                        I have shipped my vehicles a few times and have experience with many vehicle shipping companies,  I can say..
                                        <a tabindex="-1" class="gray" title='Google review' target="_blank" href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSUNlczZucUxBEAE!2m1!1s0x0:0x9bf3f822de592a5" class="gray">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Joyce Castillo
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review3(Jonathan).webp" alt="Jonathan Houk">
        
                                    <span class="text">
                                        Danielle did an awesome job making the car shipping process simple. She was able to get the car shipped at a good..
                                        <a tabindex="-1" class="gray" title='Google review' target="_blank" href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURzcExDckxnEAE!2m1!1s0x0:0x9bf3f822de592a5" class="gray">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Jonathan Houk
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review4(Skylar).webp" alt="Skylar Thompson">
        
                                    <span class="text">
                                        The vehicle Shipping group was great to deal with! Their representative was very helpful and friendly to deal..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.6897494,-122.5520322,17z/data=!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSUNjemVyN1lnEAE!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Skylar Thompson
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review5(Guy).webp" alt="Guy Buckman">
        
                                    <span class="text">
                                        They actually stored my car for a week so I would have time to beat the car transporter back to Indiana. My car..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSUQ2OWNhNDRBRRAB!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Guy Buckman
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review6(Bryana).webp" alt="Bryana George">
        
                                    <span class="text">
                                        Danielle was full of information on the industry and explained how the whole process was going to work so I knew..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURzeEtyaVFnEAE!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Bryana George
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review7(David).webp" alt="David Reed">
        
                                    <span class="text">
                                        First and foremost Danielle at VSG is absolutely the best, not only at customer relations but also very knowledgeable..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSUQ2dGVHWHBnRRAB!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    David Reed
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review8(Johnny).webp" alt="Johnny Fairchild">
        
                                    <span class="text">
                                        We are still shopping around, but our initial impression is that they are extremely knowledgable, Fair priced..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURzd3ZLblNnEAE!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Johnny Fairchild
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review9(Joshua).webp" alt="Joshua Dosch">
        
                                    <span class="text">
                                        Amazing company! Very helpful and friendly! Would strongly recommend this company to anybody thinking of shipping a vehicle..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURzOFB1ZEp3EAE!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Joshua Dosch
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review10(Bryan).webp" alt="Bryan H">
        
                                    <span class="text">
                                        Amazing prices and outstanding service..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSURzaVAyampBRRAB!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Bryan H
                                </p>
                            </div>
        
                            <!-- Copy for animation -->

                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading="lazy" class="logo" src="../assets/reviews/review1(Justin).webp" alt="Justin B">
        
                                    <span class="text">
                                        Great experience working with Danielle moving our car from Oregon to Texas. The price was great and most..
                                        <a tabindex="-1" class="gray" title='Google review' title="Google review" target="_blank" href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSUNxa191OWJ3EAE!2m1!1s0x0:0x9bf3f822de592a5" class="gray">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Justin B
                                </p>
        
                                
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review2(Joyce).webp" alt="Joyce Castillo">
                                    <span class="text">
                                        I have shipped my vehicles a few times and have experience with many vehicle shipping companies,  I can say..
                                        <a tabindex="-1" class="gray" title='Google review' target="_blank" href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSUNlczZucUxBEAE!2m1!1s0x0:0x9bf3f822de592a5" class="gray">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Joyce Castillo
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review3(Jonathan).webp" alt="Jonathan Houk">
        
                                    <span class="text">
                                        Danielle did an awesome job making the car shipping process simple. She was able to get the car shipped at a good..
                                        <a tabindex="-1" class="gray" title='Google review' target="_blank" href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURzcExDckxnEAE!2m1!1s0x0:0x9bf3f822de592a5" class="gray">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Jonathan Houk
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review4(Skylar).webp" alt="Skylar Thompson">
        
                                    <span class="text">
                                        The vehicle Shipping group was great to deal with! Their representative was very helpful and friendly to deal..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.6897494,-122.5520322,17z/data=!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSUNjemVyN1lnEAE!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Skylar Thompson
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review5(Guy).webp" alt="Guy Buckman">
        
                                    <span class="text">
                                        They actually stored my car for a week so I would have time to beat the car transporter back to Indiana. My car..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSUQ2OWNhNDRBRRAB!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Guy Buckman
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review6(Bryana).webp" alt="Bryana George">
        
                                    <span class="text">
                                        Danielle was full of information on the industry and explained how the whole process was going to work so I knew..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURzeEtyaVFnEAE!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Bryana George
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review7(David).webp" alt="David Reed">
        
                                    <span class="text">
                                        First and foremost Danielle at VSG is absolutely the best, not only at customer relations but also very knowledgeable..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSUQ2dGVHWHBnRRAB!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    David Reed
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review8(Johnny).webp" alt="Johnny Fairchild">
        
                                    <span class="text">
                                        We are still shopping around, but our initial impression is that they are extremely knowledgable, Fair priced..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURzd3ZLblNnEAE!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Johnny Fairchild
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review9(Joshua).webp" alt="Joshua Dosch">
        
                                    <span class="text">
                                        Amazing company! Very helpful and friendly! Would strongly recommend this company to anybody thinking of shipping a vehicle..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURzOFB1ZEp3EAE!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Joshua Dosch
                                </p>
                            </div>
        
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review10(Bryan).webp" alt="Bryan H">
        
                                    <span class="text">
                                        Amazing prices and outstanding service..
                                        <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/reviews/@45.689667,-122.5520429,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSURzaVAyampBRRAB!2m1!1s0x0:0x9bf3f822de592a5" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Bryan H
                                </p>
                            </div>
                            
                        </div>
                    </div>
                    <div class="tape-conteiner">
                        <div data-place="second" class="tape" (click)="togglReviewsAnimation($event,'second')">
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Marwin">
    
                                    <span class="text">
                                        Pick up date 4 Aug, delivery 12 Aug both as promised. The reason I chose Vehicle Shipping Group(VSG) to ship my vehicle was..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/294623" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Marwin
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="HappyCustomer">
    
                                    <span class="text">
                                        VSG and the carrier were great to work with. VSG had a carrier lined up faster than we anticipated it would be picked..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/296131" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    HappyCustomer
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Elbert">
    
                                    <span class="text">
                                        I spoke to several companies regarding the moving of a Acura MDX from Houston, Tx. To Buffalo, NY and only until I spoke..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/296589" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Elbert
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(f).webp" alt="Michael Morrison">
    
                                    <span class="text">
                                        Great communication and on time pick up and delivery. Would highly recommend to anyone looking to ship a vehicle from state to..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/296824" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Michael Morrison
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Kurt">
    
                                    <span class="text">
                                        Very fast service at a good price. Car was delivered on time with no scratches or any other damage. Will do business again with them..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/297393" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Kurt
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Fabian Seijas">
    
                                    <span class="text">
                                        I would Highly Recommend Vehicle Shipping Group and Sarah Navarro. I called on Friday night, she got back to me with a pick up..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/297463" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Fabian Seijas
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Mark Berry">
     
                                    <span class="text">
                                        This is my first time transporting a vehicle. I called six weeks prior to pick up. May be typical but didn't know until last minute..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/297656" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Mark Berry
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(f).webp" alt="Amanda">
    
                                    <span class="text">
                                        Great service, was able to ship my car from NY to AZ with no issues. Trucking company was ok with me shipping stuff in my car..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/299616" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Amanda
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Scott Emsley">
    
                                    <span class="text">
                                        Quick response. Clear transportation outline. Quick pickup and organization of a more difficult vehicle. Speedy transfer of..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/300010" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Scott Emsley
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Xiaoran Zhang">
    
                                    <span class="text">
                                        Laura was very attentative to my needs. This was my first time shipping a personal vehicle across states, and I felt 100%..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/301246" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Xiaoran Zhang
                                </p>
                            </div>

                            <!-- Copy for animation -->
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Marwin">
    
                                    <span class="text">
                                        Pick up date 4 Aug, delivery 12 Aug both as promised. The reason I chose Vehicle Shipping Group(VSG) to ship my vehicle was..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/294623" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Marwin
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="HappyCustomer">
    
                                    <span class="text">
                                        VSG and the carrier were great to work with. VSG had a carrier lined up faster than we anticipated it would be picked..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/296131" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    HappyCustomer
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Elbert">
    
                                    <span class="text">
                                        I spoke to several companies regarding the moving of a Acura MDX from Houston, Tx. To Buffalo, NY and only until I spoke..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/296589" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Elbert
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(f).webp" alt="Michael Morrison">
    
                                    <span class="text">
                                        Great communication and on time pick up and delivery. Would highly recommend to anyone looking to ship a vehicle from state to..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/296824" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Michael Morrison
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Kurt">
    
                                    <span class="text">
                                        Very fast service at a good price. Car was delivered on time with no scratches or any other damage. Will do business again with them..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/297393" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Kurt
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Fabian Seijas">
    
                                    <span class="text">
                                        I would Highly Recommend Vehicle Shipping Group and Sarah Navarro. I called on Friday night, she got back to me with a pick up..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/297463" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Fabian Seijas
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Mark Berry">
     
                                    <span class="text">
                                        This is my first time transporting a vehicle. I called six weeks prior to pick up. May be typical but didn't know until last minute..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/297656" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Mark Berry
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(f).webp" alt="Amanda">
    
                                    <span class="text">
                                        Great service, was able to ship my car from NY to AZ with no issues. Trucking company was ok with me shipping stuff in my car..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/299616" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Amanda
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Scott Emsley">
    
                                    <span class="text">
                                        Quick response. Clear transportation outline. Quick pickup and organization of a more difficult vehicle. Speedy transfer of..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/300010" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Scott Emsley
                                </p>
                            </div>
    
                            <div class="review_card">
                                <div class="main-sec">
                                    <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review-person(m).webp" alt="Xiaoran Zhang">
    
                                    <span class="text">
                                        Laura was very attentative to my needs. This was my first time shipping a personal vehicle across states, and I felt 100%..
                                        <a tabindex="-1" class="gray" title='Transport review' href="https://www.transportreviews.com/Company/Vehicle-Shipping-Group-LLC/Reviews/301246" target="_blank">More</a>
                                    </span>
                                </div>
                                <p class="post">
                                    Xiaoran Zhang
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </section>
        </div>

    <!-- Footer -->
    <div class="section-wrapper" [@footer]="selectedSection">
    <footer [attr.data-disabled]="selectedSections.includes('footer') ? true : false" [@footerElements]="selectedSection" (@footerElements.done)="animationFinished($event)" id="footer" class="footer">
        <main class="main">
            <div class="first-wrapper">
                <img draggable="false" class="logo"  src="../assets/Other/vsg-white.svg" alt="Diesel Dispatch logo">
                <div class="text-wrapper">
                    <h1 class="title">
                        Vehicle Shipping Group Is Your Best Business Partner.
                    </h1>
                    <h2 class="sub-text">
                        Our focus Is simply to build the best auto transport experience for thousands of customers & drivers daily!
                    </h2>
                </div>
                <div class="email-wrapper">
                    <div class="email-conteiner">
                        <input tabindex="-1" autocomplete="off" (focus)="question.classList.remove('success');" #question [(ngModel)]="messageContent" type="text" name="questions" id="question-input"
                        placeholder="Say hello, or ask your question here..."
                        class="input"
                        >
                        <button tabindex="-1" (click)="postMessage()" class="button">
                            Send
                        </button>
                    </div>  
                </div>
            </div>
            <div class="second-wrapper">
                <div (click)="goToQuote()" id="getQuote" class="getAQuote-wrapper getA">
                    <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                    <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                </div>
                <div class="bottom">
                    <span class="license">
                        Vehicle Shipping Group © {{year}}
                    </span>
                    <div (click)="backToTop()" class="backtotop">
                        <img draggable="false" class="img" src="../assets/Other/trending-up.svg" alt="">
                        <span class="text">Back To The Top</span>
                    </div>
                </div>
            </div>
            
        </main>
    </footer>
    </div>
</div>
    