import { trigger, state, style,stagger, animate,animateChild, transition, query, sequence,group, keyframes } from '@angular/animations';

export const menu = trigger(
    'menu',
    [
      transition(
        ':enter',
        [
          style({
             opacity: 0,
             transform: 'translateX(100%)'
          }),
          animate(
            '0.5s ease-out',
            style({
                opacity: 1,
                transform: 'translateX(0%)'
            })
          )
        ]
      ),
      transition(
        ':leave',
        [
          style({
            opacity: 1,
            transform: 'translateX(0%)'
          }),
          animate(
            '0.5s ease-out',
            style({
                opacity: 0,
                transform: 'translateX(100%)'
            })
          )
        ]
      )
    ]
)

export const header = trigger(
  'header',
  [
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('header => void',[
      group([
        query('.main-text .title', [
          style({
            opacity: '1'
          }),
          animate('0.7s ease-in',style({
            opacity: '0'
          }))
        ],{optional: true})
      ])
    ]),
    transition('stats => void,service => void,footer => void,careers => void,solutions => void,faqs => void,howIt => void',[
      style({
        transform: 'translateY(-800%)'
      }),
      animate(
      '1s linear',
      style({
        transform: 'translateY(-800%)'
      }))
    ]),
    transition('footer => header',[
      style({
        transform: 'translateY(-100%)'
      }),
      animate('0.1s ease-out',style({
        transform: 'translateY(-80%)'
      })),
      animate('0.1s ease-out',style({
        transform: 'translateY(0%)'
      }))
    ]),
    transition('header => stats',
    [
      style({
        transform: 'translateY(0%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-20%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-100%)'
        })
      )
    ]),
    transition('stats => header',
    [
      style({
        transform: 'translateY(-100%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-80%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(0%)'
        })
      )
    ])
  ]
)

export const headerElements = trigger(
  'headerElements',[
    transition('none => header',
    [
      group([
        query('.nav__logo', [
          style({
            opacity: '0'
          }),
          animate('0.4s ease-in',style({
            opacity: '1'
          }))
        ]),
        query('.nav__sidebar', [
          style({
            opacity: '0'
          }),
          animate('0.5s ease-in',style({
            opacity: '1'
          }))
        ]),
        query('.quote', [
          style({
            opacity: '0',
            transform: 'translateY(100%)'
          }),
            animate('1.5s ease-out',style({
              opacity: '0',
              transform: 'translateY(100%)'
            })),
            animate('1s ease-out',
            style({
              transform: 'translateY(0%)',
              opacity: '1',
            })
            )
        ],{optional: true}),
        query('.track', [
          style({
            opacity: '0',
            transform: 'translateY(100%)'
          }),
            animate('1.5s ease-out',style({
              opacity: '0',
              transform: 'translateY(100%)'
            })),
            animate('1.2s ease-out',
            style({
              transform: 'translateY(0%)',
              opacity: '1'
            })
            )
        ]),
        query('.getAQuote', [
          style({
            opacity: '0',
            transform: 'translateY(15%)'
          }),
            animate('1.3s ease-out',style({
              opacity: '0',
              transform: 'translateY(15%)'
            })),
            animate('1s ease-out',
            style({
              transform: 'translateY(0%)',
              opacity: '1'
            })
            )
        ],{optional: true}),
        query('.cont p', [
          style({
            transform: 'translateY(20%)',
            opacity: '0',
          }),
          stagger(40,[
            animate('0.5s ease-out',style({
              transform: 'translateY(20%)',
              opacity: '0',
            })),
            animate('1s ease-out',
            style({
              transform: 'translateY(0%)',
              opacity: '1'
            })
            )
          ]),
        ]),
        query('.sub-text', [
          style({
            transform: 'translateY(20%)',
            opacity: '0'
          }),
          animate('1.6s ease-out', style({
            transform: 'translateY(20%)',
            opacity: '0'
          })),
          animate('0.5s ease-out',style({
            transform: 'translateY(0%)',
            opacity: '1'
          }))
        ])
      ])
    ]),
    
  ]
  
)

export const stats = trigger(
  'stats',
  [
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('stats => void',
    [
      style({
        transform: 'translateY(-100%)'
      }),
      animate(
      '1s linear',
      style({
        transform: 'translateY(-100%)'
      })
    )]),
    transition('none => stats',
    [ 
      style({
        transform: 'translateY(-100%)'
      }),
      query('@statsElements',[
        animateChild()
      ])
    ]),
    transition('stats => header',
    [
      style({
        transform: 'translateY(-100%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-80%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(0%)'
        })
      )
    ]),
    transition('header => stats',
    [
      group([
        query("@statsElements:not([data-disabled='true'])",[
          animateChild()
        ], {optional: true}),
        sequence([
          style({
            transform: 'translateY(0%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-20%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-100%)'
            })
          )
        ])
      ]),
      
    ]),
    transition('stats => service',
    [
      style({
        transform: 'translateY(-100%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-120%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-200%)'
        })
      )
    ]),
    transition('service => stats',
    [
      style({
        transform: 'translateY(-200%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-180%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-100%)'
        })
      )
    ])
  ]
)

export const statsElements = trigger(
  'statsElements',[
    transition('header => stats,menu => stats,none => stats',[
      group([
        query('.text p',[
          style({
            opacity: '0',
            transform: 'translateY(30%)',
          }),
          stagger(120,[
            animate('0.2s ease-out',style({
              opacity: '0',
              transform: 'translateY(30%)',
            })),
            animate('1s ease-out',style({
              opacity: '1',
              transform: 'translateY(0%)'
            }))
          ])
        ]),
        query('.stats-tape, .arrow-toggls',[
          style({
            opacity: 0,
            transform: 'translate3d(*,30%,0)'
          }),
          animate('0.9s ease-out',style({
            opacity: 0,
            transform: 'translate3d(*,30%,0)'
          })),
          animate('0.8s ease-out',style({
            opacity: 1,
            transform: 'translate3d(*,0%,0)'
          }))
        ], {optional: true}),
        query('.image-down',[
          style({
            opacity: 0,
            transform: '{{rotation}}',
            zIndex: -1
          }),
          animate('0.7s ease-out',style({
            opacity: 0,
            transform: '{{rotation}}',
            zIndex: -1
          })),
          animate('1.5s ease-out',style({
            opacity: 1,
            transform: 'translate(0%,0%)',
            zIndex: -1
          }))
        ]),
        query('.getA',[
          style({
            opacity: 0,
          }),
          animate('0.9s ease-out',style({
            opacity: 0,
          })),
          animate('1.2s ease-out',style({
            opacity: 1,
          }))
        ]),
        query('.stats .stat',[
          style({
            opacity: '0',
            transform: 'translateY(30%)',
          }),
          stagger(120,[
            animate('1s ease-out',style({
              opacity: '0',
              transform: 'translateY(30%)',
            })),
            animate('1s ease-out',style({
              opacity: '1',
              transform: 'translateY(0%)'
            }))
          ])
        ], { optional: true}),
      ])
    ])
  ]
)

export const service = trigger(
  'service',
  [
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('service => void',
    [
      style({
        transform: 'translateY(-200%)'
      }),
      animate(
      '1s linear',
      style({
        transform: 'translateY(-200%)'
      })
    )]),
    transition('none => service',
    [
      style({
        transform: 'translateY(-200%)'
      }),
      query('@serviceElements',[
        animateChild()
      ])
    ]),
    transition('service => map',
    [
      style({
        transform: 'translateY(-200%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-220%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-300%)'
        })
      )
    ]),
    transition('stats => service',
    [
      group([
        query("@serviceElements:not([data-disabled='true'])",[
          animateChild()
        ], {optional: true}),
        sequence([
          style({
            transform: 'translateY(-100%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-120%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-200%)'
            })
          )
        ])
      ])
    ]),
    transition('map => service',
    [
      style({
        transform: 'translateY(-300%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-280%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-200%)'
        })
      )
    ]),
    transition('service => stats',
    [
      style({
        transform: 'translateY(-200%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-180%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-100%)'
        })
      )
    ])
  ]
)

export const serviceElements = trigger(
  'serviceElements',[
    transition('stats => service,menu => service,none=>service',[
      group([
        query('.main-text-container .title',[
          style({
            opacity: 0,
          }),
          animate('0.4s linear',style({
            opacity: 0,
          })),
          animate('1s linear',style({
            opacity: 1,
          }))
        ]),
        query('.main-text-container .text',[
          style({
            opacity: 0,
          }),
          animate('0.6s linear',style({
            opacity: 0,
          })),
          animate('1s linear',style({
            opacity: 1,
          }))
        ]),
        query('.title-conteiner .title',[
          style({
            opacity: 0,
          }),
          animate('0.8s linear',style({
            opacity: 0,
          })),
          animate('1s linear',style({
            opacity: 1,
          }))
        ]),
        query('.types-conteiner',[
          style({
            opacity: 0,
            transform: 'translateY(5%)'
          }),
          animate('0.8s linear',style({
            opacity: 0,
            transform: 'translateY(5%)'
          })),
          animate('1s linear',style({
            opacity: 1,
            transform: 'translateY(0%)'
          }))
        ]),
        query('.toggl-arrows',[
          style({
            opacity: 0,
            transform: 'translateY(20%)'
          }),
          animate('1.4s linear',style({
            opacity: 0,
            transform: 'translateY(20%)'
          })),
          animate('1s linear',style({
            opacity: 1,
            transform: 'translateY(0%)'
          }))
        ],{optional:true}),
      ])
    ])
  ]
)

export const map = trigger(
  'map',
  [
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('none => map',
    [
      style({
        opacity: 0
      }),
      animate(
        '1.5s ease-out',
        style({
          opacity: 1
        })
      )
    ]),
    transition('map => service',
    [
      group([
        sequence([
          style({
            transform: 'translateY(-300%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-280%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-200%)'
            })
          ),
        ]),
        query('.gradient-overlay1, .gradient-overlay2',[
          style({
            transform: 'translateY(0%)'
          }),
          animate('1s linear',style({
            transform: 'translateY(0%)'
          }))
        ])
      ])
      
    ]),
    transition('service => map',
    [
      group([
        query("@mapElements:not([data-disabled='true'])",[
          animateChild()
        ], {optional: true}),
        sequence([
          style({
            transform: 'translateY(-200%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-220%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-300%)'
            })
          )
        ]),
        query('.gradient-overlay1, .gradient-overlay2',[
          style({
            transform: 'translateY(0%)'
          }),
          animate('1s linear',style({
            transform: 'translateY(0%)'
          }))
        ],{optional: true})
      ])
      
    ]),
    transition('map => howIt',
    [
      group([
        sequence([
          style({
            transform: 'translateY(-300%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-320%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-400%)'
            })
          ),
        ]),
        query('.gradient-overlay1, .gradient-overlay2',[
          style({
            transform: 'translateY(0%)'
          }),
          animate('1s linear',style({
            transform: 'translateY(0%)'
          }))
        ])
      ])
      
    ]),
    transition('howIt => map',
    [
      group([
        sequence([
          style({
            transform: 'translateY(-400%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-380%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-300%)'
            })
          ),
        ]),
        query('.gradient-overlay1, .gradient-overlay2',[
          style({
            transform: 'translateY(0%)'
          }),
          animate('1s linear',style({
            transform: 'translateY(0%)'
          }))
        ])
      ])
      
    ])
  ]
)

export const mapElements = trigger(
  'mapElements',
  [
    transition('service => map,menu => map',[
      group([
        query('.title', [
          style({
            opacity: 0
          }),
          animate('0.3s',style({
            opacity: 0
          })),
          animate('0.5s',style({
            opacity: 1
          }))
        ]),
        query('.map-wrapper', [
          style({
            opacity: 0
          }),
          animate('0.3s',style({
            opacity: 0
          })),
          animate('1s',style({
            opacity: 1
          }))
        ])
      ])
    ])
  ]
  
)

export const howIt = trigger(
  'howIt',[
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('none => howIt',
    [
      style({
        opacity: 0
      }),
      animate(
        '1.5s ease-out',
        style({
          opacity: 1
        })
      )
    ]),
    transition('howIt => map',
    [
      group([
        sequence([
          style({
            transform: 'translateY(-400%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-380%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-300%)'
            })
          ),
        ])
      ])
      
    ]),
    transition('map => howIt',
    [
      group([
        query("@howItElements:not([data-disabled='true'])",[
          animateChild()
        ], {optional: true}),
        sequence([
          style({
            transform: 'translateY(-300%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-320%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-400%)'
            })
          )
        ])
      ])
      
    ]),
    transition('howIt => careers',
    [
      group([
        sequence([
          style({
            transform: 'translateY(-400%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-420%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-500%)'
            })
          ),
        ])
      ])
      
    ]),
    transition('careers => howIt',[
      group([
        sequence([
          style({
            transform: 'translateY(-500%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-480%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-400%)'
            })
          ),
        ])
      ])
    ]
  )]
)

export const howItElements = trigger(
  'howItElements',[
    transition(
      'map => howIt, menu => howIt, none => howIt, header => howIt',[
        group([
          query('.howIt-title', [
            style({
              opacity: 0,
            }),
            animate('0.2s ease-out',style({
              opacity: 0,
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
            }))
          ],{optional: true}),
          query('.logo-wrapper',[
            style({
              opacity: 0,
            }),
            animate('0.2s ease-out',style({
              opacity: 0,
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
            }))
          ]),
          query('.heading__wrapper',[
            style({
              opacity: 0,
            }),
            animate('0.4s ease-out',style({
              opacity: 0,
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
            }))
          ]),
          query('.text__wrapper',[
            style({
              opacity: 0,
            }),
            animate('0.6s ease-out',style({
              opacity: 0,
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
            }))
          ]),
          query('.button-container .button',[
            style({
              opacity: 0,
              transform: 'translateY(-20%)'
            }),
            animate('0.8s ease-out',style({
              opacity: 0,
            transform: 'translateY(-20%)'
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
              transform: 'translateY(0%)'
            }))
          ]),
          query('.button-container .chevron-button',[
            style({
              opacity: 0,
              transform: 'translateY(-20%)'
            }),
            animate('1s ease-out',style({
              opacity: 0,
              transform: 'translateY(-20%)'
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
              transform: 'translateY(0%)'
            }))
          ],{optional: true})
        ])
      ]
    )
  ]
)

export const careers = trigger(
  'careers',
  [
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('none => careers',
    [
      style({
        transform: 'translateY(-500%)'
      }),
      query('@careersElements',[
        animateChild()
      ])
    ]),
    transition('careers => void',[
      style({
        transform: 'translateY(-500%)'
      }),
      animate(
        '1s ease-out',
        style({
          transform: 'translateY(-500%)'
        })
      )
    ]),
    transition('careers => howIt',
    [
      style({
        transform: 'translateY(-500%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-480%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-400%)'
        })
      )
    ]),
    transition('howIt => careers',
    [
      group([
        query("@careersElements:not([data-disabled='true'])",[
          animateChild()
        ], {optional: true}),
        sequence([
          style({
            transform: 'translateY(-400%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-420%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-500%)'
            })
          )
        ])
      ])
    ]),
    transition('careers => faqs',
    [
      style({
        transform: 'translateY(-500%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-520%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-600%)'
        })
      )
    ]),
    transition('faqs => careers',
    [
      style({
        transform: 'translateY(-600%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-580%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-500%)'
        })
      )
    ])
  ]
)

export const careersElements = trigger(
  'careersElements',[
    transition(
      'howIt => careers, menu => careers,header => careers,none=>careers',[
        group([
          query('.title',[
            style({
              opacity: 0,
              transform: 'translateY(-20%)'
            }),
            animate('0.8s ease-out',style({
              opacity: 0,
            transform: 'translateY(-20%)'
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
              transform: 'translateY(0%)'
            }))
          ]),
          query('.sub-text',[
            style({
              opacity: 0,
              transform: 'translateY(-40%)'
            }),
            animate('0.8s ease-out',style({
              opacity: 0,
            transform: 'translateY(-40%)'
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
            transform: 'translateY(0%)'
            }))
          ]),
          query('.button',[
            style({
              opacity: 0,
              transform: 'translateY(-40%)'
            }),
            animate('1.1s ease-out',style({
              opacity: 0,
            transform: 'translateY(-40%)'
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
            transform: 'translateY(0%)'
            }))
          ]),
          query('.gradient',[
            style({
              height: '100%'
            }),
            animate('0.2s ease-in',style({
              height: '100%'
            })),
            animate('0.5s ease-in',style({
              height: '0%'
            }))
          ])
      ]
    )
    ]),

])

export const faqs = trigger(
  'faqs',
  [
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('faqs => void',
    [
      style({
        transform: 'translateY(-600%)'
      }),
      animate(
      '1s linear',
      style({
        transform: 'translateY(-600%)'
      })
    )]),
    transition('none => faqs',
    [
      style({
        transform: 'translateY(-600%)'
      })
    ]),
    transition('careers => faqs',
    [
      group([
        query("@faqsElements:not([data-disabled='true'])",[
          animateChild()
        ], {optional: true}),
        sequence([
          style({
            transform: 'translateY(-500%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-520%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-600%)'
            })
          )
        ])
      ])
    ]),
    transition('faqs => reviews',
    [
      style({
        transform: 'translateY(-600%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-680%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-700%)'
        })
      )
    ]),
    transition('reviews => faqs',
    [
      style({
        transform: 'translateY(-700%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-680%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-600%)'
        })
      )
    ]),
    transition('faqs => careers',
    [
      style({
        transform: 'translateY(-600%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-580%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-500%)'
        })
      )
    ])
  ]
)

export const faqsElements = trigger(
  'faqsElements',
  [
    transition('careers => faqs,menu => faqs',[
      group([
        query('.text-image,.title',[
          style({
            opacity: 0,
            transform: 'translateY(20%)'
          }),
          stagger(50,[
            animate('0.3s linear',style({
              opacity: 0,
              transform: 'translateY(20%)'
            })),
            animate('0.5s linear',style({
              opacity: 1,
              transform: 'translateY(0%)'
            }))
          ])
        ]),
          query('#card-1,#card-2,#card-3,#card-4,#card-5,#card-6,#card-7,#card-8,#card-9,#card-10,#card-11,#card-12', [
            style({
              opacity: 0,
              transform: 'translateY(10%)'
            }),
            stagger(50,[
              animate('0.7s linear',style({
                opacity: 0,
                transform: 'translateY(10%)'
              })),
              animate('0.8s linear',style({
                opacity: 1,
                transform: 'translateY(0%)'
              }))
            ])
          ],{optional:true}),
          query('.getA,.bottom',[
            style({
              opacity: 0,
              transform: 'translateY(20%)'
            }),
              animate('1.4s linear',style({
                opacity: 0,
                transform: 'translateY(20%)'
              })),
              animate('1s linear',style({
                opacity: 1,
                transform: 'translateY(0%)'
              }))
          ])
      ])
    ])
  ]
)

export const reviews = trigger(
  'reviews',
  [
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('none => reviews',
    [
      style({
        transform: 'translateY(-700%)'
      }),
      query('@reviewsElements',[
        animateChild()
      ])
    ]),
    transition('reviews => faqs',
    [
      style({
        transform: 'translateY(-700%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-680%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-600%)'
        })
      )
    ]),
    transition('faqs => reviews',
    [
      group([
        query("@reviewsElements:not([data-disabled='true'])",[
          animateChild()
        ], {optional: true}),
        sequence([
          style({
            transform: 'translateY(-600%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-620%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-700%)'
            })
          )
        ])
      ])
    ]),
    transition('reviews => footer',
    [
      style({
        transform: 'translateY(-700%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-720%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-800%)'
        })
      )
    ]),
    transition('footer => reviews',
    [
      style({
        transform: 'translateY(-800%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-780%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-700%)'
        })
      )
    ])
  ]
)

export const reviewsElements = trigger(
  'reviewsElements',[
    transition(
      'faqs => reviews, menu => reviews,header => reviews,none=>reviews',[
        group([
          query('.title',[
            style({
              opacity: 0,
              transform: 'translateY(-30%)'
            }),
            animate('0.2s ease-out',style({
              opacity: 0,
              transform: 'translateY(-30%)'
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
              transform: 'translateY(0%)'
            }))
          ]),
          query('.tape_wrapper [data-place="first"]',[
            style({
              opacity: 0,
            }),
            animate('0.7s ease-out',style({
              opacity: 0,
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
            }))
          ]),
          query('.tape_wrapper [data-place="second"]',[
            style({
              opacity: 0,
            }),
            animate('1.1s ease-out',style({
              opacity: 0,
            })),
            animate('0.8s ease-out',style({
              opacity: 1,
            }))
          ])
        ])
      ]
    )
  ]
)

export const footer = trigger(
  'footer',
  [
    state('header',style({
      transform: 'translateY(0%)'
    })),
    state('stats',style({
      transform: 'translateY(-100%)'
    })),
    state('service',style({
      transform: 'translateY(-200%)'
    })),
    state('map',style({
      transform: 'translateY(-300%)'
    })),
    state('howIt',style({
      transform: 'translateY(-400%)'
    })),
    state('careers',style({
      transform: 'translateY(-500%)'
    })),
    state('faqs',style({
      transform: 'translateY(-600%)'
    })),
    state('reviews',style({
      transform: 'translateY(-700%)'
    })),
    state('footer',style({
      transform: 'translateY(-800%)'
    })),
    transition('footer => void',
    [
      style({
        transform: 'translateY(-800%)'
      }),
      animate(
      '1s linear',
      style({
        transform: 'translateY(-800%)'
      })
    )]),
    transition('footer => header',
    [
      style({
        transform: 'translateY(-800%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-780%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-700%)'
        })
      )
    ]),
    transition('footer => reviews',
    [
      style({
        transform: 'translateY(-800%)'
      }),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-780%)'
        })
      ),
      animate(
        '0.1s ease-out',
        style({
          transform: 'translateY(-700%)'
        })
      )
    ]),
    transition('reviews => footer',
    [
      group([
        query("@footerElements:not([data-disabled='true'])",[
          animateChild()
        ], {optional: true}),
        sequence([
          style({
            transform: 'translateY(-700%)'
          }),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-720%)'
            })
          ),
          animate(
            '0.1s ease-out',
            style({
              transform: 'translateY(-800%)'
            })
          )
        ])
      ])
    ])
  ]
)

export const footerElements = trigger(
  'footerElements',[
    transition('reviews => footer,menu => footer',[
      group([
        query('.logo',[
          style({
            opacity: 0
          }),
          animate('0.2s ease-out',style({
            opacity: 0
          })),
          animate('0.8s ease-out',style({
            opacity: 1
          }))
        ]),
        query('.text-wrapper .title',[
          style({
            opacity: 0
          }),
          animate('0.4s ease-out',style({
            opacity: 0
          })),
          animate('0.8s ease-out',style({
            opacity: 1
          }))
        ]),
        query('.text-wrapper .sub-text',[
          style({
            opacity: 0,
            transform: 'translateY(30%)'
          }),
          animate('0.4s ease-out',style({
            opacity: 0,
            transform: 'translateY(30%)'
          })),
          animate('0.8s ease-out',style({
            opacity: 1,
            transform: 'translateY(0%)'
          }))
        ]),
        query('.email-wrapper .subscribe,.email-wrapper .text',[
          style({
            opacity: 0,
            transform: 'translateY(30%)'
          }),
          stagger(200,[
            animate('0.5s ease-out',style({
              opacity: 0,
              transform: 'translateY(30%)'
            })),
            animate('1.2s ease-out',style({
              opacity: 1,
              transform: 'translateY(0%)'
            }))
          ])
        ], { optional: true}),
        query('.email-conteiner',[
          style({
            opacity: 0,
            transform: 'translateY(30%)'
          }),
          animate('0.7s ease-out',style({
            opacity: 0,
            transform: 'translateY(30%)'
          })),
          animate('0.8s ease-out',style({
            opacity: 1,
            transform: 'translateY(0%)'
          }))
        ]),
        query('.getA',[
          style({
            opacity: 0
          }),
          animate('1.2s ease-out',style({
            opacity: 0
          })),
          animate('0.8s ease-out',style({
            opacity: 1
          }))
        ]),
        query('.bottom',[
          style({
            opacity: 0,
            transform: 'translate3d(*,50%,*)'
          }),
          animate('1.2s ease-out',style({
            opacity: 0,
            transform: 'translate3d(*,15%,*)'
          })),
          animate('1.2s ease-out',style({
            opacity: 1,
            transform: 'translate3d(*,0%,*)'
          }))
        ]),
      ])
    ])
  ]
)

export const mobileServiceToggl = trigger(
  'mobileServiceToggl',[
    state('0',style({
      transform: 'translateX(0%)'
    })),
    state('1',style({
      transform: 'translateX(calc(-100% - min(11.1vw,6.56vh)))'
    })),
    state('2',style({
      transform: 'translateX(calc(-200% - min(22.2vw,13.11vh)))'
    })),
    state('3',style({
      transform: 'translateX(calc(-300% - min(33.3vw,19.67vh)))'
    })),
    state('4',style({
      transform: 'translateX(calc(-400% - min(44.4vw,26.20vh)))'
    })),
    state('5',style({
      transform: 'translateX(calc(-500% - min(55.5vw,32.78vh)))'
    })),
    state('6',style({
      transform: 'translateX(calc(-600% - min(66.6vw,39.34vh)))'
    })),
    transition('1 <=> 2,2 <=> 3,3 <=> 4,4 <=> 5, 5 => 6, 1 => 0',[
      animate('0.4s ease-out')
    ]),
    transition('6 => 1, 0 => 5',[
      animate('0s')
    ])
  ]
)
export const desktopServiceToggl = trigger(
  'desktopServiceToggl',[
    transition('1 => 2',[
      style({
        transform: '{{first}}'
      }),
      animate('0.4s ease-out',style({
        transform: '{{second}}'
      }))
    ]),
    transition('2 => 1',[
      style({
        transform: '{{second}}'
      }),
      animate('0.4s ease-out',style({
        transform: '{{first}}'
      }))
    ]),
    transition('2 => 3',[
      style({
        transform: '{{second}}'
      }),
      animate('0.4s ease-out',style({
        transform: '{{third}}'
      }))
    ]),
    transition('3 => 2',[
      style({
        transform: '{{third}}'
      }),
      animate('0.4s ease-out',style({
        transform: '{{second}}'
      }))
    ]),
    transition('3 => 4',[
      style({
        transform: '{{third}}'
      }),
      animate('0.4s ease-out',style({
        transform: '{{last}}'
      }))
    ]),
    transition('4 => 3',[
      style({
        transform: '{{last}}'
      }),
      animate('0.4s ease-out',style({
        transform: '{{third}}'
      }))
    ]),
    transition('1 => 0',[
      animate('0.6s linear',keyframes([
        style({transform: '{{first}}',offset: 0.2}),
        style({transform: '{{firstShake}}',offset: 0.4}),
        style({transform: '{{first}}',offset: 0.6}),
        style({transform: '{{firstShake}}',offset: 0.8}),
        style({transform: '{{first}}',offset: 1})
      ]))
    ]),
    transition('2 => 9,3 => 9,4 => 9',[
      // style({
      //   transform: 'translateX(-1257px)'
      // }),
      animate('0.6s linear',keyframes([
        style({transform: '{{last}}',offset: 0.2}),
        style({transform: '{{lastShake}}',offset: 0.4}),
        style({transform: '{{last}}',offset: 0.6}),
        style({transform: '{{lastShake}}',offset: 0.8}),
        style({transform: '{{last}}',offset: 1})
      ]))
    ]),
    transition('1 <=> 2,2 <=> 3,3 <=> 4,4 <=> 5',[
      animate('0.4s ease-out')
    ]),
  ]
)
export const faqsCardFade = trigger(
  'faqsCardFade',[
    transition(':enter',[
      group([
        query('@faqsToggl',[
          animateChild()
        ],{optional:true}),
        style({
          opacity: '0',
          transform: 'translate3d(*,25%,*)'
        }),
        animate('0.7s ease-out',style({
          opacity: '1',
          transform: 'translate3d(*,0%,*)'
        }))
      ])
    ]),
    transition(':leave',[
      group([
        query('@faqsToggl',[
          animateChild()
        ],{optional:true}),
        style({
          opacity: '1',
          transform: 'translate3d(*,0%,*)',
        }),
        animate('0.7s ease-out',style({
          opacity: '0',
          transform: 'translate3d(*,-25%,*)',
        }))
      ])
    ])
  ]
)

export const mapArrow = trigger(
  'mapArrow',[
    transition('void => *',[
      style({
        opacity: '0',
        transform: 'translate3d(50%,50%,0)'
      }),
      animate('0.3s ease-out',
        style({
          opacity: '1',
          transform: 'translate3d(50%,0%,0)'
        })
      )
    ]),
    transition('* => void',[
      style({
        opacity: '1',
        transform: 'translate3d(50%,0%,0)'
      }),
      animate('0.3s ease-out',
        style({
          opacity: '0',
          transform: 'translate3d(50%,50%,0)'
        })
      )
    ])
  ]
)
export const fadeOutIn = trigger(
  'fadeOutIn',[
    transition(":enter",[
      style({
        opacity: 0
      }),
      animate('0.5s linear',style({
        opacity: 1
      }))
    ]),
    transition(":leave",[
      style({
        opacity: 1
      }),
      animate('0.5s linear',style({
        opacity: 0
      }))
    ])
  ]
)

export const serviceFadeOutIn = trigger(
  'serviceFadeOutIn',[
    transition(":enter",[
      style({
        opacity: 0,
        position: 'absolute',
        top: 0
      }),
      animate('0.4s linear',style({
        opacity: 1,
        position: 'absolute',
        top: 0
      }))
    ]),
    transition(":leave",[
      style({
        opacity: 1,
      }),
      animate('0.4s linear',style({
        opacity: 0,
      }))
    ])
  ]
)

export const howItNoiseFlyTop = trigger(
  'howItNoiseFlyTop',[
    state('1',style({
      top: '-10%',
      right: '63%',
      opacity: 1
    })),
    state('2',style({
      top: '25%',
      right: '30%',
      opacity: 0
    })),
    state('3',style({
      opacity: 0
    })),
    transition(
      '1 => 2',[
        animate(
          '0.35s',keyframes([
            style({
              top: '25%',
              right: '30%',
              offset: 0.5
            }),
            style({
              opacity: 0,
              offset: 1
            })
          ])
        )
      ]
    ),
    transition(
      '2 => 1',[
        animate(
          '0.35s',keyframes([
            style({
              top: '25%',
              right: '30%',
              offset: 0.5
            }),
            style({
              top: '-10%',
              right: '63%',
              opacity: 1,
              offset: 1
            }),
          ])
        )
      ]
    )
  ]
)

export const howItNoiseFlyRight = trigger(
  'howItNoiseFlyRight',[
    state('1',style({
      top: '25%',
      right: '20%',
      opacity: 0
    })),
    state('2',style({
      top: '13%',
      right: '5%',
      opacity: 1
    })),
    state('3',style({
      top: '13%',
      right: '5%',
      opacity: 0
    })),
    transition(
      "1 => 2",[
        animate(
          "0.35s", keyframes([
            style({
              top: '25%',
              right: '20%',
              offset: 0.5
            }),
            style({
              top: '13%',
              right: '5%',
              opacity: 1,
              offset: 1
            }),
          ])
        )
      ]
    ),
    transition(
      "2 => 1",[
        animate(
          "0.35s", keyframes([
            style({
              top: '25%',
              right: '20%',
              offset: 0.5
            }),
            style({
              opacity: 0,
              offset: 1
            })
          ])
        )
      ]
    ),
    transition(
      "2 => 3",[
        animate(
          "0.35s", keyframes([
            style({
              top: '25%',
              right: '20%',
              offset: 0.5
            }),
            style({
              opacity: 0,
              offset: 1
            })
          ])
        )
      ]
    ),
    transition(
      "3 => 2",[
        animate(
          "0.35s", keyframes([
            style({
              top: '25%',
              right: '20%',
              offset: 0.5
            }),
            style({
              top: '13%',
              right: '5%',
              opacity: 1,
              offset: 1
            })
          ])
        )
      ]
    ),
  ]
)

export const howItNoiseFlyBottom = trigger(
  'howItNoiseFlyBottom',[
    state('1',style({
        top: '37%',
        right: '25%',
        opacity: 0
    })),
    state('2',style({
        top: '37%',
        right: '22%',
        opacity: 0
    })),
    state('3',style({
      opacity: 1,
      top: '69%',
      right: '-12%',
    })),
    transition(
      '2 => 3',[
        animate(
          '0.35s',keyframes([
            style({
              top: '37%',
              right: '22%',
              offset: 0.5
            }),
            style({
              top: '69%',
              right: '-12%',
              opacity: 1,
              offset: 1
            })
          ])
        )
      ]
    ),
    transition(
      '3 => 2',[
        animate(
          '0.35s',keyframes([
            style({
              top: '37%',
              right: '22%',
              offset: 1
            }),
            style({
              opacity: 0,
              offset: 1
            })
          ])
        )
      ]
    )
  ]
)
