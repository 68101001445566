import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment'
import { Observable, from } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AppService{
    path:string = '/public/landing'

    constructor(private http: HttpClient){}

    private setHeaders(): HttpHeaders {
        const headersConfig = {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        };
    
        return new HttpHeaders(headersConfig);
    }

    private getBackendHost():string{
      return environment.apiUrl
    }

    public getQuoteFormHost():string{
      return environment.quoteFormUrl
    }

    public getCandFormHost():string{
      return environment.candFormUrl
    }

    public postMessage(message):Observable<any>{
    let content = {
        type: 'QUESTION',
        content: message,
        from: 'Vehicle Shipping Group'
    } 
    
    return this.http.post<any>(`${this.getBackendHost()}${this.path}`, JSON.stringify(content), {
        headers: this.setHeaders()
      })
    }
}